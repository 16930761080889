import { createRouter, createWebHistory } from 'vue-router'
//import Front from "@/views/Front";

function lazyLoad(view){
  return() => import(`@/views/${view}.vue`)
}

const router = createRouter({
  // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
  history: createWebHistory(),
  routes: getRoutes(), // short for `routes: routes`
})

export default router;

function getRoutes() {
    return [
        {
            path: '/',
            name: 'home',
            component: lazyLoad('Front'),
            alias: '/home',
            meta: {
                title: 'harrylegs.ch | ' + 'Home',
                icon: 'home',
                pagetitle: 'home',
            },
        },
        {
            path: '/wolken',
            name: 'wolken',
            component: lazyLoad('CloudsPage'),
            meta: {
                title: 'harrylegs.ch | ' + 'Wolken',
                icon: 'clouds',
                pagetitle: 'clouds',
            },
        },
        {
            path: '/item/:id',
            name: 'text',
            component: lazyLoad('SimpleTextView'),
            meta: {
                title: 'harrylegs.ch | ' + 'Text',
                icon: 'text',
                pagetitle: 'text',
            },
        },
    ];
}

// This callback adds editors-infos, page-titles etc. for the specific page
// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {

    //item.editors.roles.filter(role => {return role === $store.getters.getUserRole})
    //if(to.meta.accessPermission && !to.meta.staticPermission) {
        //next('/access-denied');
    //}

    // This goes through the matched routes from last to first, finding the closest route with a title.
    // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

    // Find the nearest route element with editors tags.
    const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
    // eslint-disable-next-line no-unused-vars
    //const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.editors && r.editors.metaTags);

    // If a route with a title was found, set the document (page) title to that value.
    if (nearestWithTitle) document.title = nearestWithTitle.meta.title;

    // Remove any stale editors tags from the document using the key attribute we set below.
    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

    // Skip rendering editors tags if there are none.

    if (!nearestWithMeta) return next();

    // Turn the editors tag definitions into actual elements in the head.
    nearestWithMeta.meta.metaTags.map(tagDef => {
        const tag = document.createElement('meta');

        Object.keys(tagDef).forEach(key => {
            tag.setAttribute(key, tagDef[key]);
        });

        // We use this to track which editors tags we create, so we don't interfere with other ones.
        tag.setAttribute('data-vue-router-controlled', '');

        return tag;
    })
        // Add the editors tags to the document head.
        .forEach(tag => document.head.appendChild(tag));

    next();
});