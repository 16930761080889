import axios from 'axios';
const baseUrl = process.env.VUE_APP_API;

import { createStore } from 'vuex'

// Create a new store instance.
export const store = createStore({
  state () {
    return {
      count: 0
    }
  },
  mutations: {
    increment (state) {
      state.count++
    }
  },
  actions: {
	getEntries: async () => {
		return await axios.get(baseUrl + '/entries')
		.then(result => {
			return result;
		})
	}
  }
})
